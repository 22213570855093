import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckIcon from "@heroicons/react/24/solid/CheckIcon";

interface DemoBookingFormProps {
  referral: string | null | undefined;
  campaignId: string | null | undefined;
  translations: any;
}

const DemoBookingForm: React.FC<DemoBookingFormProps> = ({
  campaignId,
  referral,
  translations,
}) => {
  const [isDemoBooked, setIsDemoBooked] = React.useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Ogiltig e-postadress")
      .required("E-postadress krävs"),
    phone: Yup.string().required("Telefonnummer krävs"),
    acceptUserTerms: Yup.boolean()
      .oneOf([true], "Du måste acceptera användarvillkoren")
      .required("Du måste acceptera användarvillkoren"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      acceptUserTerms: false,
      campaignId,
      referral,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch("/bff/book-a-demo", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        const result = await response.json();
        if (result.success) {
          setIsDemoBooked(true);
        } else {
          console.error("Error:", result);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  if (isDemoBooked) {
    return (
      <div className="h-auto w-full text-deepPurple">
        <div className="flex flex-row justify-center items-center">
          <span className="text-2xl">
            {translations.demoBooking.form.success}
          </span>
          <CheckIcon className="h-8 w-8 text-vibrantOrange" />
          <br />
          <span className="text-2xl">
            {translations.demoBooking.form.getBack}
          </span>
        </div>
      </div>
    );
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="h-auto w-full text-deepPurple"
    >
      <div className="m-[30px]">
        <h2 className="mb-8 text-[20px] text-deepPurple">
          {"Fyll i dina uppgifter för att boka en demo"}
        </h2>
        <div className="flex flex-col mt-2 lg:mt-3">
          <label
            htmlFor="email"
            className="flex flex-row text-deepPurple items-start gap-x-2 text-[14px] leading-[22px] lg:text-[18px] lg:leading-[24px] font-bold"
          >
            {translations.registration.mainForm.placeholders.email}{" "}
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.email}
              </div>
            ) : null}
          </label>
          <input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className={`bg-transparent mt-2 lg:mt-3 text-xs lg:text-base h-[36px] lg:h-[60px] w-full
            lg:min-w-[400px] lg:max-w-[600px] px-2 py-2.5 lg:px-4 lg:py-5 text-deepPurple border
            ${
              formik.touched.email && formik.errors.email
                ? "border-red-500"
                : "border-deepPurple"
            } rounded-[10px] lg:rounded-[15px] mr-1 lg:mr-4 focus:outline-none focus:ring-2
            ${
              formik.touched.email && formik.errors.email
                ? "focus:ring-red-500"
                : "focus:ring-deepPurple"
            }`}
            placeholder={translations.registration.mainForm.placeholders.email}
            aria-label="email address"
            title="email address"
          />
        </div>
        <div className="flex flex-col mt-4 lg:mt-6">
          <label
            htmlFor="phone"
            className="flex flex-row text-deepPurple items-start gap-x-2 text-[14px] leading-[22px]
            lg:text-[18px] lg:leading-[24px] font-bold"
          >
            {translations.registration.mainForm.placeholders.phone}{" "}
            {formik.touched.phone && formik.errors.phone ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.phone}
              </div>
            ) : null}
          </label>
          <input
            id="phone"
            name="phone"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            className={`bg-transparent mt-2 lg:mt-3 text-xs lg:text-base h-[36px] lg:h-[60px]
            w-full lg:min-w-[400px] lg:max-w-[600px] px-2 py-2.5 lg:px-4 lg:py-5 text-deepPurple border
            ${
              formik.touched.phone && formik.errors.phone
                ? "border-red-500"
                : "border-deepPurple"
            } rounded-[10px] lg:rounded-[15px] mr-1 lg:mr-4 focus:outline-none focus:ring-2
            ${
              formik.touched.phone && formik.errors.phone
                ? "focus:ring-red-500"
                : "focus:ring-deepPurple"
            }`}
            placeholder={translations.registration.mainForm.placeholders.phone}
            aria-label="phone number"
            title="phone number"
          />
        </div>
        <div className="flex text-start items-center my-4 lg:my-6 col-span-1">
          <input
            id="acceptUserTerms"
            name="acceptUserTerms"
            type="checkbox"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur} // Lägg till onBlur
            checked={formik.values.acceptUserTerms}
            className={`h-3 lg:h-4 w-3 lg:w-4 mr-1.5 lg:mr-3 rounded border
            ${
              formik.touched.acceptUserTerms && formik.errors.acceptUserTerms
                ? "border-red-500"
                : "border-deepPurple"
            } text-deepPurple focus:ring-deepPurple focus:bg-deepPurple checked:bg-deepPurple`}
          />
          <label
            htmlFor="acceptUserTerms"
            className="block text-xxs lg:text-xs font-medium leading-6 text-deepPurple"
          >
            <span>
              {
                translations.registration.mainForm.placeholders.acceptUserTerms
                  .accept
              }
              <a
                href="https://www.leadcaller.com/se/villkor"
                target="_blank"
                rel="noopener noreferrer"
                className="text-vibrantOrange"
              >
                {
                  translations.registration.mainForm.placeholders
                    .acceptUserTerms.userTerms
                }
              </a>
              &nbsp;och&nbsp;
              <a
                href="https://www.leadcaller.com/se/integritetspolicy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-vibrantOrange"
              >
                {
                  translations.registration.mainForm.placeholders
                    .acceptUserTerms.privacyPolicy
                }
              </a>
            </span>
          </label>
        </div>
        {formik.touched.acceptUserTerms && formik.errors.acceptUserTerms ? (
          <div className="text-red-500 text-sm mt-1">
            {formik.errors.acceptUserTerms}
          </div>
        ) : null}
        <div className="items-center mt-4">
          <button
            type="submit"
            className={`bg-purpleAccent text-pureWhite w-full h-9 lg:h-12 rounded-[10px]
            lg:rounded-xl px-6 lg:px-[30px] py-2.5 lg:py-3 text-xs lg:text-base text-nowrap font-bold
            hover:bg-lightPurple hover:text-pureWhite hover:border-transparent transition duration-300
            ease-in-out z-20 whitespace-normal overflow-hidden`}
          >
            {translations.demoBooking.ctaBtn}
          </button>
        </div>
      </div>
    </form>
  );
};

export default DemoBookingForm;
